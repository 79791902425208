.mainContent{
        width: 82%;
        height: 100%;
        padding: 1rem;
        overflow-y: auto;
        overflow-x: hidden;

    .bottom{
        margin-top: 2rem;
        gap: 2rem;
        align-items: flex-start;
    }
}

// MEDIA QUERIES ===================>
@media screen and (max-width: 918px) {
    .bottom{
        flex-direction: column;
    }
}