.mainContent {
  width: 82%;
  height: 100%;
  padding: 1rem;
  overflow: auto;
}
.mainContent .bottom {
  margin-top: 2rem;
  gap: 2rem;
  align-items: flex-start;
}

@media screen and (max-width: 918px) {
  .bottom {
    flex-direction: column;
  }
}/*# sourceMappingURL=body.css.map */