ul li.page-item {
  cursor: pointer;
}

.banner {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.banner .logo {
  flex: 0.7 1;
  width: 100%;
  height: 100%;
  display: contents;
}
.banner .logo img {
  padding: 6rem;
}

.banner button {
  background-color: #139ff0;
  color: #fff;
  border: none;
  padding: 10px 40px;
}

.footer {
  font-size: 13px;
  line-height: 1.2;
  background: #161e2c;
  color: #f7f7f7;
  display: block;
  overflow: scroll;
  padding-bottom: env(safe-area-inset-bottom);
  position: relative;
}
.footerSection {
  font-size: 13px;
  line-height: 1.2;
  padding: 90px 0px;
}

.footer-top-content {
  padding: 18px;
  margin: -20px;
}
.footer-top-content img {
  width: 100%;
  height: 50px;
  margin: 10px -5px;
  padding-right: 36px;
}

.footer-top-content h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14.5893px;
  line-height: 20px;
}
.footer-top-content ul {
  list-style: none;
  border-bottom: 0;
  padding: 0;
}

.footer-top-content ul li {
  font-size: 14px;
  line-height: 1.43;
  position: relative;
  transition: color 0.1s ease-out;
  padding: 2px;
}

.footer-top-content ul li a {
  background: none;
  border: 0;
  color: #bec0c2;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  font-weight: 600;
  padding: 0;
  text-align: left;
}

.footer-top-content ul li a .fa {
  font-size: 22px;
  font-weight: 400;
  margin-right: 7px;
}

.footer-top-content ul li a .fa-span {
  padding-left: 26px;
}

.footer-top-content ul li a.facebook-icons {
  padding: 10px;
  border-radius: 50%;
}
.footer-top-content ul li a.twitter-icons {
  padding: 10px;
  border-radius: 50%;
}

.footer-top-content ul li a.twitter-icons:hover {
  color: #139ff0;
}

.footer-top-content ul li a.youtube-icons {
  padding: 10px;
  border-radius: 50%;
}
.footer-top-content ul li a.youtube-icons:hover {
  color: #ff0000;
}

.footer-top-content ul li a.instagram-icons {
  padding: 10px;
  border-radius: 50%;
}
.footer-top-content ul li a.instagram-icons:hover {
  color: #c13584;
}

.footer-top-content ul li a.facebook-icons:hover {
  color: #003399;
}

.footer-top-content ul li a:hover {
  color: #ffffff;
  text-decoration: underline;
}

.light-t .navbar-brand img {
  width: 8rem;
}
.light-t {
  background-color: #fff;
  padding: 5px 0px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.light-t #navbarSupportedContent {
  margin-left: 23rem;
}
.light-t .navbar-nav .nav-link {
  padding-right: 20px;
  color: #000 !important;
  font-size: 16px;
  font-weight: 600;
}

.light-t .navbar-brand {
  /* margin: 0 auto; */
  height: 50px;
}

.t-1 a i {
  position: absolute;
  top: 18px;
  left: 8px;
  border: 0 !important;
  width: auto !important;
  background-color: #000 !important;
  padding: 11px 11px !important;
  border-radius: 50%;
  color: #fff !important;
  height: auto !important;
  font-size: 22px !important;
}
.t-1 {
  position: relative;
}
li.t-1 span {
  position: absolute;
  top: -2px;
  left: 33px;
  color: #fff;
  background-color: #7c88bd;
  font-size: 12px;
  border-radius: 16px;
  font-weight: 700;
  padding: 1px 13px;
}
.btun {
  background: linear-gradient(
    111.21deg,
    #9e4fe9 -2.4%,
    #e14fa9 46.51%,
    #c92f9f 93.12%
  );
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 7px 30px;
  margin-top: 12px;
}
.navbar-light .navbar-nav .nav-link:active,
.navbar-light .navbar-nav .nav-link:hover {
  color: #7c88bd;
}

.NavDropdown ul {
  margin: 20px 0px 0px;
  padding: 0% !important;
  width: 25vw;
}

.NavDropdown ul li:nth-child(1) {
  margin: 0px;
}

.NavDropdown ul a span {
  width: 25px;
  display: inline-block;
}

.NavDropdown ul a {
  color: #000;
  background-color: transparent;
}

.NavDropdown ul a {
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  padding: 7px 7px;
}

.NavDropdown ul li:nth-child(1) {
  background-color: #000026;
  color: #fff;
  padding: 4px 26px;
  cursor: pointer;
}

.NavDropdown ul li:nth-child(1) h3 {
  font-size: 22px;
  font-weight: 500;
  line-height: 22px;
  padding: 5px 0px;
}

.NavDropdown ul li:nth-child(1) a {
  font-size: 25px;
  font-weight: 400;
  line-height: 22px;
  padding: 5px 0px;
  color: #fff;
}
.NavDropdown ul li:nth-child(1) a:hover {
  background-color: transparent;
  color: #fff;
}

.home-box ul {
  margin: 0;
  padding: 0;
  display: inline-block;
}
.home-box ul li {
  float: left;
  width: 20rem;
  text-align: center;
  list-style: none;
}
.home-box ul li i {
  float: left;
}
.home-b-1 i {
  float: left;
  margin-right: 16px;
  margin-left: -3rem;
}
.home-b-1 {
  position: relative;
  margin-left: 5rem;
}
.row-home {
  padding: 0px 0px 0px 12rem;
}
.i-1 {
  color: #3483f9;
  background-color: rgb(13 110 253 / 8%);
  border-radius: 50%;
  padding: 10px 10px;
}
.i-2 {
  color: #ee8344;
  background-color: rgb(253 46 13 / 12%);
  border-radius: 50%;
  padding: 10px 10px;
}
.i-3 {
  color: #6ccf81;
  background-color: rgb(57 253 13 / 7%);
  border-radius: 50%;
  padding: 10px 10px;
}
.i-4 {
  color: #fe0000;
  background-color: #f2000026;
  border-radius: 50%;
  padding: 10px 10px;
}
.num-user .row {
  background-color: #fff;
  border-radius: 50px;
  box-shadow: 0 2px 5px #00000054;
  margin: 0 35px;
  padding-top: 1rem;
  position: relative;
  top: -7rem;
}
.about-box h3 {
  font-weight: 700;
  font-size: 30px;
  padding-bottom: 25px;
}

.about-img img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

/* .about-us-sec .row {
  padding: 0rem 6rem;
} */
.about-box p {
  text-align: justify;
  padding: 8px 2rem;
}
.about-box {
  margin-right: 18px;
}

.about-us-sec .about-img {
  height: 270px;
  padding: 0rem 0rem 2rem;
}

.btun:hover {
  color: #fff;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.slider-sec {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.slider-sec h1 {
  padding-bottom: 2rem;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
}
.slide-1 {
  box-shadow: 0 3px 8px rgb(0 0 0 / 8%);
  padding: 18px;
  border-radius: 15px;
  margin: 0rem 1rem 0rem 0rem;
  position: relative;
  cursor: pointer;
  height: 350px;
}
.slide-1 p {
  color: #fff;
  line-height: 22px;
  padding-bottom: 1px;
}
.slide-1 button {
  border: none;
  background-color: #0000001a;
  color: #000;
  font-weight: 600;
  margin: 0 auto;
  display: block;
  padding: 8px 85px;
  border-radius: 20px;
}
.slide-1 a {
  text-decoration: none;
}

.slide-1 .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.b {
  font-size: 20px;
  background-color: #0000001a;
  border-radius: 50%;
  padding: 18px 19px;
  margin-bottom: 1rem;
}
.slider-sec .row {
  padding: 0rem 2rem;
}
.slide-1::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    111.21deg,
    #9e4fe9 -2.4%,
    #e14fa9 46.51%,
    #c92f9f 93.12%
  );
  border-radius: 14px;
  z-index: -1;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
.slide-1 h2 {
  color: #fff;
  font-size: 16px;
  width: 85%;
  padding: 0px 10px;
}

.slide-1:hover p {
  color: #000;
}
.slide-1:hover h2 {
  color: #000;
}

.slide-1:hover i {
  color: #000;
}

.slide-1 i {
  color: #fff;
  padding: 16px;
}

.slide-1:hover i {
  color: #000;
}

.slide-1:hover:before {
  border-color: #afafaf54;
  background: #afafaf;
  border: 2px solid #afafaf;
  box-shadow: 0px 0px 3px rgb(139 137 137);
}
.slide-1:hover button {
  color: #fff;
}
.cat-1 img {
  width: 100%;
  height: 100%;
}
.cat-1 {
  box-shadow: 0px 0px 3px rgb(139 137 137);
  padding: 20px 53px 50px;
  margin-bottom: 1rem;
  border-radius: 10px;
  height: 240px;
}
.cate-section .row {
  padding: 0rem 6rem;
}
.cate-section h1 {
  padding-bottom: 2rem;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
}
.cat-1 h4 {
  text-align: center;
  padding-top: 12px;
}

/**slider**/
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.slider-why {
  position: relative;
  padding-top: 2rem;
  background: #fff9fb;
}
.slider-why::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  background-color: #000;
  border-radius: 50% 50% 0 0/100% 100% 0 0;
  transform: scalex(1.5);
}
.swip-box h2 {
  margin-bottom: 2rem;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
}
.swip-box p {
  text-align: left;
  font-size: 16px;
  padding: 0rem 2rem 0rem 0rem;
}
.slider-why .swiper-backface-hidden .swiper-slide {
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: #fff9fb;
}

.slider-why .swiper-button-next {
  right: 10px;
  left: 10rem;
  top: 23rem;
}
.swiper-button-prev {
  left: 6rem;
  right: auto;
  top: 23rem;
}
.slider-why .swiper-wrapper {
  transform: translate3d(0px, 0, 0);
  margin-bottom: 4rem;
}
.slider-why .swiper-android .swiper-slide,
.swiper-wrapper {
  margin-bottom: 4rem;
}
.slider-why .swiper-button-next:after {
  content: "next";
  font-size: 18px;
  background-color: red;
  border-radius: 50%;
  padding: 10px 14px;
  color: #fff;
  font-weight: 800;
}
.slider-why .swiper-button-prev:after {
  content: "next";
  font-size: 18px;
  background-color: red;
  border-radius: 50%;
  padding: 10px 14px;
  color: #fff;
  font-weight: 800;
}
.project-section {
  background-color: #fff;
  padding: 51px 0px;
}

.project-section .product-box {
  /* margin-left: 50px; */
  border-radius: 12px 0px 0px 12px;
}
.product-img img {
  width: 100%;
  height: 100%;
  display: block;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  border-radius: 0px 12px 12px 0px;
}
.project-section .slick-slide.slick-active.slick-current {
  padding-bottom: 20px;
  padding-top: 20px;
}

.choose-img {
  width: 100%;
  height: 300px;
}

.choose-img img {
  width: 100%;
  height: 100%;
}

.image-upload {
  display: flex;
  justify-content: flex-end;
  margin: -1px -22px 0px 0px;
}

.product-box {
  background: linear-gradient(
    111.21deg,
    #9e4fe9 -2.4%,
    #e14fa9 46.51%,
    #c92f9f 93.12%
  );
  height: 100%;
  padding: 0rem 6rem;
  color: #fff;
}

.product-box h3 {
  padding-top: 2rem;
  padding-bottom: 3rem;
}
.project-section .col-md-6 {
  padding: 0;
}

.project-section .row {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 0px 12px 12px 12px;
}

.project-section .slick-slide > div:first-child {
  padding-left: 20px;
  padding-right: 20px;
}

.product-img {
  /* padding: 0px 40px; */
  padding-right: 40px;
}

.our-clients,
.slider-why,
.slider-sec,
.num-user,
.about-us-sec,
.cate-section,
.project-section {
  background-color: #fe919b29;
}

.our-clients .accordion-item {
  background-color: #ffedef;
  border: none;
}

.slider-why .swiper-slide {
  background-color: #fd929b03;
}

.carousel-control-prev,
.carousel-control-next {
  display: none;
}

.about-us-sec {
  padding: 0px !important;
}

.our-clients,
.about-us-sec {
  padding-bottom: 2rem;
  padding-top: 3rem;
}

.our-clients h1 {
  text-align: center;
  font-size: 30px;
  font-weight: 700;
}
.our-clients p {
  text-align: center;
  padding-top: 1rem;
}
.reviews {
  background-color: #fe919b29;
  padding: 2rem 0rem 0rem 0rem;
}
.reviews-box h2 {
  font-weight: 700;
  width: 17rem;
}
.reviews-box p {
  font-size: 18px;
  width: 22rem;
}
.img-d img {
  width: 6.5rem;
  border-radius: 50%;
  height: 6.5rem;
  margin-right: 1rem;
}

.our-clients h1 {
  padding: 2rem 0px;
}

.clients-img {
  height: 125px;
}
.clients-img img {
  width: 100%;
  height: 100% !important;
}
.img-d img {
  float: left;
}

.img-d {
  margin-top: 3rem;
}

.img-d .name\&des {
  width: 100%;
  height: 6.5rem;
  float: inherit;
  padding: 1.5rem 0rem 0rem;
}

.img-d .img-para {
  padding: 6rem 2rem 0rem;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  font-size: 16px;
  text-align: justify;
  text-overflow: ellipsis;
}
.clients-img {
  padding: 0px 18px;
}
.foot-box img {
  width: 12rem;
  margin-bottom: 10px;
}

.footer-section {
  background: linear-gradient(
    111.21deg,
    #9e4fe9 -2.4%,
    #e14fa9 46.51%,
    #c92f9f 93.12%
  );
  padding: 2rem 0rem 2rem 0rem;
  color: #fff;
  position: relative;
}
.foot-box {
  padding-top: 15px;
}

.foot-box h4 {
  margin-bottom: 10px;
  font-size: 20px;
}

.foot-box ul {
  margin: 0;
  padding: 0;
  display: inline-block;
  font-size: 15px;
}

.foot-box ul li {
  list-style: none;
  line-height: 26px;
  font-size: 15px;
}

.foot-box-1 h4 {
  margin-bottom: 15px;
  font-size: 20px;
  padding-top: 15px;
}

.foot-box-1 p {
  font-size: 15px;
  line-height: 24px;
}

.foot-box a {
  color: #fff;
  padding: 8px;
}
.foot-box a i {
  font-size: 16px;
}

.foot-box p {
  text-align: justify;
  padding-right: 115px;
  font-size: 15px;
  line-height: 24px;
}
.row-2-b {
  background-color: #fff;
  color: #000;
}
.row-1 .col-md-2 {
  padding: 0;
}
.row-1 .col-md-3 {
  padding: 0;
}
.row-2-b {
  background-color: #fff;
  color: #000;
  padding: 20px 29px 2px 26px;
}
.row-1 {
  position: absolute;
  top: 21.3rem;
  left: 0rem;
}
.row-2-b a {
  color: #000;
  font-weight: 500;
  text-decoration: none;
}
.bottom-footer {
  background-color: #000026;
  color: #fff;
}
.bottom-f p {
  padding-top: 0.8rem;
}
.bottom-f {
  padding: 0rem 2.5rem;
}

.app-home-header {
  margin-top: 70px;
}

.app-form-card .form-group {
  padding: 10px 0px;
}

.horizontal {
  border: 1px solid #484848;
}

.logo-section {
  padding: 1.9rem 0rem 4rem 0rem;
}
.logo-section .row {
  position: relative;
}

.logo-section .col {
  margin: 0;
  padding: 0;
}

.logo-section h5 {
  font-weight: 400;
  /* margin-right: 0rem; */
  font-size: 18px;
  padding-top: 0.2rem;
}

.great-box h4 {
  height: 5rem;
  font-weight: 700;
}
.great-box img {
  float: left;
  width: 4rem;
  margin-right: 1rem;
}
.great-box p {
  font-size: 18px;
  color: #000;
}
.great-section {
  padding: 2rem 0rem 6rem 0rem;
}

.great-section .row {
  position: relative;
}
.great-section h1 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 6rem;
}

.real-box img {
  width: 100%;
  transition: 0.5s;
}

.real-box {
  position: relative;
  overflow: hidden;
}

.real-box:hover img {
  transform: scale(1.2);
}

.real-box:hover {
  transition: 0.5s;
}

.in-box {
  position: absolute;
  background-color: #19202d99;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: none;
  z-index: 1;
}
.make-a {
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
  padding: 8px 20px;
  border: 2px solid #fff;
  position: absolute;
  left: 3rem;
  top: 5rem;
}
.make-a:hover {
  color: #fff;
}
.real-box:hover .in-box {
  display: block;
}
.make-real-section {
  padding: 5rem 0rem 8rem 0rem;
}

.make-real-section h1 {
  font-size: 3rem;
  font-weight: 700;
  padding-bottom: 1.5rem;
}
.make-real-section h6 {
  font-size: 20px;
  padding-bottom: 2rem;
  font-weight: 400;
}
.real-c h3 {
  font-size: 24px;
  width: 13rem;
  font-weight: 700;
}
.real-c {
  padding: 1rem 0rem 2.6rem 0rem;
}
.pro-a button {
  border: none;
  background-color: #f20091;
  color: #fff;
  padding: 10px 30px;
  display: block;
  margin: 0 auto;
  font-size: 20px;
  transition: 0.3s;
  font-weight: 500;
  margin-top: 3rem;
}
.pro-a {
  text-decoration: none;
}

.pro-a button:hover {
  color: #f7f7f7;
  background-color: #d90082;
}
.diff-img {
  width: 11rem;
}
.diff-img img {
  width: 2rem;
  float: left;
  margin-right: 0.5rem;
  cursor: pointer;
}
.diff-img a {
  color: #000;
  text-decoration: none;
}

.diff-img a:hover {
  text-decoration: underline;
  text-decoration-color: #000;
}
.diff-img {
  width: 11rem;
  margin-bottom: 2.5rem;
}
.different-categories-sec {
  padding: 4rem 0rem 5rem 0rem;
}
.different-categories-sec h1 {
  font-size: 3rem;
  font-weight: 700;
  padding-bottom: 4rem;
}

.api-box img {
  width: 100%;
}
/**API-SECTION**/
.api-box {
  position: relative;
}
.api-in {
  position: absolute;
  top: 11rem;
  left: 5rem;
  color: #fff;
}
.api-in h5 {
  font-weight: 700;
}
.api-in h3 {
  font-weight: 700;
  width: 27rem;
  font-size: 2.3rem;
  padding-bottom: 0.5rem;
}
.api-in p {
  padding-bottom: 1rem;
  width: 23rem;
  text-align: justify;
}
.api-in button {
  padding: 8px 22px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background-color: #f20091;
  border-radius: 5px;
}
.api-section .col-md-6 {
  padding: 0;
}

.container .app-form-card {
  background-color: #fff;
  border-radius: 15px;
  overflow: auto;
  height: 550px;
  box-shadow: 0px 30px 60px rgb(0 0 0 / 30%);
}
.app-form-card::-webkit-scrollbar {
  display: none;
}

.myProfileCard {
  padding-left: 40px;
  padding-right: 40px;
}

.app-login {
  display: block;
  margin: 0 auto;
  padding-bottom: 24px;
  padding-top: 24px;
}

.app-login .form-group input {
  border: 1px solid #9b9b9b;
}

.app-form-logo {
  display: flex;
  /* padding: 24px 0 0; */
}

.app-form-logo-content {
  display: flex;
  flex: 1;
  justify-content: center;
}

.app-form {
  background-color: #fff;
  padding: 40px 80px;
}

.login_right_sec {
  height: 550px;
}

.login_right_sec .LogoImg {
  height: 48px;
  width: 199px;
}
.app-packaging-logo {
  height: 375px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.right_login_con {
  position: inherit !important;
  margin-top: 35px;
  text-align: center;
}
.right_login_con p {
  font-size: 16px;
  color: #82869a;
  font-weight: 500;
  letter-spacing: 1px;
  font-family: "Poppins", sans-serif;
}

.app-form-logo {
  display: flex;
  margin-top: 40px;
  justify-content: center;
}

.app-form h2 {
  color: #0e1724;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.4;
}
.app-form .subheader {
  position: relative;
}
.app-form .subheader::after {
  content: "";
  position: absolute;
  border: 1px solid #afafaf;
  width: 45%;
  top: 14px;
  right: 0;
}
.app-form .subheader::before {
  content: "";
  position: absolute;
  border: 1px solid #afafaf;
  width: 45%;
  top: 14px;
  left: 0;
}

.app-form .app-facebook-button {
  display: block;
  line-height: 1.3;
  margin-bottom: 24px;
  box-sizing: border-box;
}

.app-form .app-facebook-button .facebook {
  background-color: #4267b2;
  border-color: #3b5c9f;
  color: #f7f7f7;
  display: flex;
  justify-content: center;
  font-size: 20px;
  line-height: 1.4;
  padding: 2px 22px;
  border-radius: 4px;
}

.FacebookImg {
  height: 24px;
  width: 24px;
  margin-right: 5px;
  margin-bottom: 2px;
}

.app-form .app-facebook-button button {
  background: inherit;
  font-size: 20px;
  color: #fff;
  border: none;
  padding: 2px;
  font-weight: 600;
}

.app-form .form-group .form-control:focus {
  border-color: #139ff0;
  box-shadow: none;
}

.app-form .app-form-checkbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
}

.app-form .app-form-checkbox a {
  color: #139ff0;
}

.app-form .app-login-active {
  margin-bottom: 24px;
  background-color: #f7f7f7;
  border-color: #f7f7f7;
  color: #4d525b;
  display: flex;
  justify-content: center;
}

.app-form .app-login-button {
  font-size: 20px;
  width: 100%;
  font-weight: 600;
  background-color: #139ff0;
  border-color: #139ff0;
  color: #f7f7f7;
}

.app-form .app-form-forgot-password {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
}

.app-form .app-form-forgot-password a {
  color: #139ff0;
  margin-left: 10px;
}
.app-form .app-form-forgot-password p {
  font-weight: 700;
  color: #606060;
}

.app-form .app-form-forgot-password a:hover {
  text-decoration: underline;
}

.app-form .app-download-app {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.app-form .app-download-app img {
  width: 100%;
  height: 50px;
  padding: 4px;
}

.post-project {
  background: linear-gradient(55deg, #164188, #007fed);
  width: 100%;
  height: 65vh;
}

.post-project-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: fit-content;
  background-color: inherit;
  color: #fff;
  padding: 2rem 11rem 0rem;
}
.post-project-header img {
  width: 40%;
  height: 100%;
  padding: 20px;
}
.post-project-header h1 {
  color: #f7f7f7;
  font-weight: 700;
  font-size: 32px;
  line-height: 1.37;
  padding: 2px 3%;
}
.post-project-header p {
  color: #f7f7f7;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.43;
  padding: 2px 3%;
}

.post-project-body {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: fit-content;
  background-color: inherit;
  color: #000;
  padding: 1rem 11rem 0rem;
  border-radius: 6px;
}

.post-project-body .post-content {
  background-color: #fff;
  width: 100%;
  height: fit-content;
  border-radius: 4px;
  padding: 2rem;
}

.post-project-body .post-content h3 {
  font-size: 22px;
  line-height: 1.43;
  font-weight: 700;
  color: #0e1724;
  font-style: normal;
}

.post-project-body .post-content form {
  margin-bottom: 32px;
}

.alert-box {
  background-color: #fff;
  border: 1px solid #dedede;
  border-left: 4px solid #dedede;
  border-radius: 5px;
  border-left-color: #29b2fe;
  padding: 1rem;
}

.alert-box p {
  font-size: 14px;
  line-height: 1.43;
  font-weight: 400;
  color: #0e1724;
  font-style: normal;
}

.alert-box h3 {
  font-size: 22px;
  line-height: 1.43;
  font-weight: 500;
  font-style: normal;
  color: #000;
}

.btn-alert {
  background-color: #f7f7f7;
  color: #139ff0;
  font-size: 16px;
  line-height: 1.43;
  font-weight: 700;
}

.btn-alert:hover {
  color: #139ff0;
  text-decoration: underline;
}

.post-content .form-group {
  padding: 18px 0px;
}

.wrapper-skill-content .form-group {
  padding: 0px !important;
}

.post-content label {
  font-size: 22px;
  line-height: 1.43;
  font-weight: 400;
  color: #0e1724;
  padding: 5px 0px;
  font-style: normal;
}

.post-content .form-group input {
  border-color: #afafaf;
}

.post-content input.form-check-input {
  margin-top: 12px;
}

.post-content .form-group textarea {
  border-color: #afafaf;
}

.post-content .form-group input:focus {
  border-color: #139ff0;
  box-shadow: none;
}

.post-content .form-group textarea:focus {
  border-color: #139ff0;
  box-shadow: none;
}

.attach-box {
  border: 1px dashed #b3b3b3;
  border-radius: 5px;
  margin-bottom: 8px;
  margin-top: 1rem;
  padding: 20px 16px;
}

.attach-box p {
  color: #4d525b;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.43;
}

.btn-attach {
  width: 10rem;
  float: left;
  margin-right: 10px;
}

.table-attach td:nth-child(1) {
  width: 30px;
}
.table-attach td:nth-child(4) span i {
  display: flex;
  align-items: center;
  height: 20px;
  width: 20px;
  cursor: pointer;
  color: #808080;
  font-size: 12px;
  border-radius: 50%;
  background: #dfdfdf;
  justify-content: center;
}

.btn-attach:active {
  border-color: #000 !important;
  margin-right: 10px;
}

.skill-section {
  padding: 1rem;
}

.btn-next {
  font-size: 14px;
  line-height: 1.43;
  padding: 6px 13px;
  background: #007fed;
  border-color: #007fed;
  color: #f7f7f7;
  text-align: center;
  font-weight: 700;
  border: 1px solid;
  border-radius: 3px;
  cursor: pointer;
}
.btn-next:hover {
  background: #007fed;
  color: #f7f7f7;
}

.wrapper-skill {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 18px 0px;
}

.wrapper-skill .title h3 {
  font-size: 22px;
  line-height: 1.43;
  font-weight: 700;
  color: #0e1724;
  font-style: normal;
}

.wrapper-skill .wrapper-skill-content {
  margin: 10px 0;
}

.wrapper-skill-content p {
  font-size: 15px;
}

.wrapper-skill-content ul {
  display: inline-block;
  width: 100%;
  padding: 7px;
  margin: 12px 0;
  border-radius: 5px;
  border: 1px solid #a6a6a6;
}

.wrapper-skill-content ul li {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 4px 3px;
  border-radius: 5px;
  background: #f2f2f2;
  padding: 5px 8px 5px 10px;
  border: 1px solid #e3d1e1;
  width: auto;
  float: left;
}

.wrapper-skill-content ul li i {
  display: flex;
  align-items: center;
  height: 20px;
  width: 20px;
  cursor: pointer;
  color: #808080;
  font-size: 12px;
  margin-left: 8px;
  border-radius: 50%;
  background: #dfdfdf;
  justify-content: center;
}

.wrapper-skill-content ul input {
  flex: 1;
  border: none;
  outline: none;
  padding: 5px;
  font-size: 16px;
}

.wrapper-skill .details {
  justify-content: center;
}

.wrapper-skill label {
  display: none;
}

.post-medium {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.post-medium-content {
  padding: 18px 0px;
  cursor: pointer;
  height: fit-content;
  overflow: hidden;
  border: 1px solid rgb(19 159 240 / 0%);
  border: 1px solid #139ff0;
}

.post-done {
  padding: 18px 0px;
}

.post-medium-content img {
  width: 100% !important;
  padding: 10%;
  height: 100%;
}
.post-medium-content h5 {
  font-size: 22px;
  line-height: 1.43;
  font-weight: 700;
  color: #0e1724;
  font-style: normal;
}

.post-medium-content p {
  font-size: 13px;
  line-height: 1.43;
  font-weight: 500;
  color: #0e1724;
  font-style: normal;
}

.post-project-table {
  margin-top: 1rem;
}

table .odd td {
  border-top: none !important;
}

table .odd td:nth-child(1) {
  width: 80px;
}

table .odd td:nth-child(2) div {
  width: 150px;
  text-align: center;
  padding: 0;
  color: #fff;
  border-radius: 20px;
  font-size: 14px;
  line-height: 1.43;
  font-weight: 500;
  font-style: normal;
}

table .odd td:nth-child(3) {
  font-size: 14px;
  line-height: 1.43;
  font-weight: 500;
  color: #0e1724;
  font-style: normal;
  text-align: left;
  padding: 6px 50px 22px 8px;
}

table .odd td:nth-child(4) {
  font-size: 14px;
  line-height: 1.43;
  width: 100px;
  font-weight: 500;
  color: #0e1724;
  font-style: normal;
}

button.btn.btn-submit {
  background-color: #f20091;
  color: #fff;
  border: none;
  padding: 20px 50px;
  margin: 1.25rem 0rem;
}

.post-project-footer p {
  font-size: 14px;
  line-height: 1.43;
  font-weight: 400;
  color: #0e1724;
  font-style: normal;
}

.post-project-footer a {
  font-size: 14px;
  line-height: 1.43;
  font-weight: 400;
  color: #007fed;
  font-style: normal;
}

h5.heading {
  text-align: center;
}

p.paragraph {
  text-align: center;
}

.dashboard-head-1 {
  background-color: #1073cb;
}

.light-tt {
  padding: 0;
  height: 60px;
}

.light-t .navbar-toggler:focus {
  box-shadow: none;
}

.light-tt ul {
  margin: 0;
  padding: 0;
}

.light-tt ul li {
  align-self: center;
}

.light-tt ul li:nth-child(4) {
  padding-left: 7rem;
}

.light-tt .navbar-nav .nav-link {
  color: #000;
}

.light-tt ul li i {
  float: left;
  font-size: 22px;
  margin-right: 0.5rem;
  width: auto;
  padding: 0;
  height: 40px;
  border: 1px solid #f2f2f2;
}

.light-tt button {
  width: 10rem;
  background-color: #f20091;
  border: none;
  color: #fff;
  padding: 6px 16px;
  box-shadow: none;
  font-weight: 700;
}

.light-tt button:hover {
  background-color: #f20091;
}

.light-tt button:focus:active {
  box-shadow: none;
  background-color: #f20091;
}

.light-tt .d-flex {
  margin-right: 1rem;
}

.img-li img {
  width: 2.9rem;
  border-radius: 50%;
  height: 3rem;
}

.img-li {
  list-style: none;
}

.img-box {
  display: flex;
  width: 100%;
  justify-content: end;
}

.img-box a {
  color: #000;
}

.img-box img {
  width: 3rem;
  height: 3rem;
  margin-top: 0.7rem;
  margin-right: 1rem;
}

.img-box h6 {
  color: #000;
  font-weight: 700;
  margin-top: 0.5rem;
}

.img-box p {
  color: #000;
  margin-top: -0.5rem;
}

.light-k {
  background-color: #0e1724;
}
.light-k .navbar-nav .nav-link {
  color: #fff;
  position: relative;
}
.light-k .navbar-nav .nav-link:hover {
  color: #fff;
}
.light-k .navbar-nav .nav-link {
  color: #fff;
  padding-right: 1.7rem;
}
.navbar-k .navbar-nav .nav-link:focus {
  color: #fff;
}

.user-navbar {
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: space-evenly;
}

.user-header {
  width: 15%;
}

.user-header .navbar-brand {
  padding-left: 3rem;
}

.user-body {
  width: 85%;
}

.main-d-1 img {
  float: left;
  margin-right: 1rem;
}
.main-d-1 {
  background-color: #eef6fa;
  border: 1px solid #bec0c2;
  box-shadow: 0 0 6px #dedede;
  padding: 23px 22px 40px 31px;
  margin: 23px 0px 0px 49px;
}
.main-dashborad .pe-5 {
  padding-right: 6rem !important;
}

.main-dashborad a {
  color: #007fed;
}

.main-d-1-child i {
  float: left;
  margin-right: 0.5rem;
}

.main-d-1-child {
  background-color: #fff;
  padding: 19px 0px 3px 31px;
  border-radius: 6px;
  border-left: 4px solid #29b2fe;
  margin-left: 4rem;
}
.main-d-1-child i {
  color: #29b2fe;
  font-size: 20px;
}
.main-d-1-child h6 {
  font-weight: 600;
  font-size: 15px;
}

.main-d-1-child p {
  margin-left: 1.8rem;
  font-size: 15px;
}

.set-up-account {
  box-shadow: 0 0 6px #dedede;
  padding: 25px 16px;
  margin-top: 1.5rem;
}

.set-up-account .w-75 {
  width: 56% !important;
  background: linear-gradient(
    to right,
    rgb(16, 115, 203),
    rgb(242, 0, 145) 178.571%
  );
}

.set-up-account h5 {
  font-size: 16px;
  padding-bottom: 1rem;
}
span.font-ten {
  margin-left: 6rem;
  font-weight: 400;
  font-size: 14px;
}
.set-up-account .progress {
  height: 0.5rem;
  font-size: 0.75rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}
.set-up-account p {
  font-size: 15px;
  line-height: 20px;
  color: #000;
}
.phone-div i {
  float: left;
  margin-right: 1.5rem;
  color: gray;
  font-size: 25px;
}
.phone-div {
  border: 1px solid;
  padding: 23px 28px;
  border-radius: 6px;
}
.phone-div {
  border: 1px solid #f20091;
  padding: 23px 28px;
  margin-top: 1.3rem;
  border-radius: 6px;
  transition: 0.3s;
}
.phone-div:hover {
  box-shadow: 0 0 6px #0000001f, 0 4px 10px #00000029;
}
.main-dashborad a {
  text-decoration: none;
}
span.p-s {
  padding: 0px 0px 0px 33px;
}
.set-up-account-2 {
  background-color: #fff;
  box-shadow: 0 0 6px #dedede;
  margin-top: 1rem;
  padding: 16px 18px 8px 20px;
}
.set-up-account-2 ul li {
  list-style: none;
  line-height: 3rem;
}
.set-up-account-2 ul {
  margin: 0px;
  padding: 0px;
  display: inline-block;
}
.i-s {
  padding-left: 8rem;
}
.set-up-account-2 ul li h5 {
  font-size: 16px;
}
.set-up-account-2 a {
  color: #f20091;
  margin-left: 11.5rem;
  font-weight: 700;
  font-size: 14px;
}
.set-up-account-2 ul li img {
  width: 1.8rem;
}
.zro-img img {
  width: 100%;
  margin-top: 2rem;
}
.zro-img {
  background-color: #104b99;
  padding: 5rem 0rem 2rem 0rem;
  position: relative;
  border-radius: 5px;
  margin-top: 1rem;
}

.set-up-account-2 a i {
  border: 1px solid;
  border-radius: 50%;
  padding: 2px 3px;
}
.zero-content {
  position: absolute;
  top: 29px;
  left: 34px;
  color: #fff;
}
.zero-content h3 {
  font-weight: 700;
}
.jobs-near-box ul {
  margin: 0;
  padding: 0;
  display: inline-block;
}
.jobs-near-box ul li {
  list-style: none;
  color: #000;
  margin-bottom: 1.5rem;
  padding: 8px 0px 0px 13px;
  transition: 0.2s;
}
.jobs-near-box ul li:hover {
  background-color: aliceblue;
}
.jobs-near-box ul li:hover h6 {
  color: #d90082;
}
.jobs-near-box ul li p {
  font-size: 14px;
}

p.i-p {
  width: 15rem;
}

.jobs-near-box ul li i {
  color: #f20091;
}
.need-d {
  padding-left: 7rem;
}
.need-f {
  padding-left: 10rem;
}
.need-g {
  padding-left: 10rem;
}
.jobs-near-box {
  padding: 25px 0px 2px 23px;
  box-shadow: 0 0 6px #dedede;
  margin-top: 1rem;
}
.jobs-near-box h6 {
  font-size: 14px;
}
.submit-a {
  background: #f7f7f7;
  color: #4d525b;
  border: 1px solid #bec0c2;
  padding: 6px 14px;
  font-weight: 700;
  border-radius: 3px;
  margin-right: 1rem;
}
.skip-a {
  color: #4d525b;
  font-weight: 700;
}
.submit-a:hover {
  background: #f0f0f0;
  border-color: #bec0c2;
  color: #4d525b;
}

.pools-box {
  margin-bottom: 5rem;
  margin-top: 1rem;
  box-shadow: 0 0 6px #dedede;
  padding: 13px 16px 30px 29px;
}
.pools-box p {
  font-size: 15px;
  font-weight: 500;
}
.pools-box h6 {
  font-size: 17px;
  margin-bottom: 1.5rem;
}
.from-div {
  margin-bottom: 2rem;
}

.td-words-linit {
  float: right;
  font-size: 15px;
  line-height: 20px;
  color: #4d525b;
  font-weight: 600;
  padding: 6px;
}

.forms-details .form-group label {
  display: none;
}

.category-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
}

.category-col select {
  width: 220px;
  margin-right: 2px;
}

.gst-card {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  height: 70px;
  padding: 6px;
}

.gst-card i {
  color: #fff;
  font-size: 32px;
  width: auto;
}

.gst-notification {
  width: auto;
  color: #fff;
  padding: 0px 10px;
}

.gst-notification h1 {
  font-size: 16px;
  line-height: 1.2;
  font-weight: 500;
  font-style: normal;
}

.gst-notification p {
  font-size: 16px;
  line-height: 1.1;
  font-weight: 400;
  font-style: normal;
}

.gst-notification a {
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
  color: #fff;
  font-style: normal;
}

.tabContainer {
  margin: 0;
  padding: 0;
  background-color: #ff7a35;
}

.tabContainer ul {
  border-color: #ff7a35 #ff7a35 #fff !important;
}

.tabContainer ul li {
  border-radius: 0px !important;
  padding-top: 8px;
}

.tabContainer ul li .nav-link {
  color: #dedede;
  font-weight: 500;
  font-style: normal;
}

.tabContainer ul li .nav-link.active {
  border-color: #ff7a35 #ff7a35 #fff !important;
  background-color: #ff7a35;
  color: #fff;
  font-weight: 700;
  font-style: normal;
}

.tabContainer ul li .nav-link:hover {
  border-color: #ff7a35;
}

.tabContainer ul li button {
  border-color: #ff7a35 #ff7a35 #fff;
  border-radius: 0px !important;
}

.tabContainer ul li button:active {
  border-radius: 0px !important;
}

.req {
  display: flex;
  margin-top: 60px;
  justify-content: space-between;
  background-color: #139ff0;
  flex-direction: row;
}

.req .req-title {
  margin-top: 1%;
  margin-left: 10%;
}

.req .req-button {
  margin-right: 10%;
}

.req .req-title h1 {
  font-size: 2rem;
  line-height: 1.43;
  font-weight: 500;
  font-style: normal;
  color: #fff;
}

.req .req-button button:nth-child(1) {
  color: #fff;
  background-color: #f20091;
  border: 1px solid #f20091;
  padding: 0.3rem 0.7rem;
  font-size: 0.99rem;
  border-radius: 0.25rem;
  margin-top: 0.8rem;
  font-weight: 600;
  width: 170px;
  margin-right: 10px;
}

.req .req-button button:nth-child(2) {
  color: #fff;
  background-color: inherit;
  border: 1px solid #fff;
  padding: 0.3rem 0.7rem;
  font-size: 0.99rem;
  border-radius: 0.25rem;
  margin-top: 0.8rem;
  font-weight: 600;
  width: 170px;
}

.browse-title h1 {
  font-size: 2rem;
  line-height: 1.43;
  font-weight: 500;
  font-style: normal;
  color: #fff;
  padding: 19px 6px;
}

.search {
  padding: 12px 0px;
}

.search label {
  display: none;
}

.search .form-group input:focus {
  border-color: #139ff0;
  box-shadow: none;
}

.btn-search {
  color: #fff;
  background-color: #139ff0;
  border: 1px solid #139ff0;
  padding: 0.3rem 0.7rem;
  font-size: 0.99rem;
  border-radius: 0.25rem;
  font-weight: 600;
  width: 170px;
  margin-right: 10px;
}
.btn-search:hover {
  color: #fff;
  background-color: inherit;
}

.settings,
.clientview,
.reqdetails,
.proposals,
.bClient,
.bRequirement,
.membership-card,
.requirementContainer {
  background-color: #ededed;
  padding: 10px 0px;
}

.membership-banner {
  background: linear-gradient(
    89.77deg,
    #001431 0.2%,
    #001431 28.82%,
    #001635 47.84%,
    #001e4f 63.32%,
    #00205a 78.74%,
    #001945 99.97%
  );
  padding: 10px 0px;
}

.clientreqCard .list-group .list-group-item {
  border-radius: 0px;
}

.filter ul li h1 {
  font-size: 32px;
  line-height: 1.43;
  font-weight: 500;
  font-style: normal;
  color: #000;
}

.RequirementList h3 {
  font-size: 22px;
  line-height: 1.43;
  font-weight: 500;
  font-style: normal;
  color: #000;
}

.bClientList h3 {
  float: left;
  font-size: 22px;
  line-height: 1.43;
  font-weight: 500;
  font-style: normal;
  color: #000;
}
.bClientList .form-group {
  float: right;
}
.bClientList .form-group label {
  display: none;
}

.bRequirementList h3 {
  float: left;
  font-size: 22px;
  line-height: 1.43;
  font-weight: 500;
  font-style: normal;
  color: #000;
}

.bRequirementList .form-group {
  float: right;
}

.bRequirementList .select .form-group div:first-child {
  display: none;
}

.bRequirementList .list-group,
.filter .list-group {
  border: none;
  background: #eeeff1;
}

.bRequirementList .list-group .list-group-item,
.filter .list-group .list-group-item {
  border: 1px solid #eeeff1;
  background: #fecf77;
  border-radius: 16px;
  margin: 1px 0px;
}
.bRequirementList .form-control {
  border-radius: 16px;
  border-color: #9b9b9b;
}

.bRequirementList ul li:nth-child(1) {
  background: none;
}

.btn-filter:hover {
  background-color: #fff;
}

.filter ul li h3 {
  font-size: 16px;
  float: left;
  line-height: 1.43;
  font-weight: 500;
  font-style: normal;
  color: #000;
}

.filter ul li a {
  float: right;
  font-size: 16px;
  line-height: 1.43;
  font-weight: 500;
  font-style: normal;
  color: #139ff0;
}

.bRequirementList select {
  border-radius: 16pc;
}

.bRequirementList select .form-select:focus {
  border: #fff;
  outline: 0;
  box-shadow: none;
}

.settings .clientview,
.reqdetails,
.proposals,
.bClient .container {
  padding: 13px 16px 30px 2px;
}

.bRequirement .container {
  padding: 13px 16px 30px 2px;
}

.filter ul li {
  border-color: #fff;
}
.filterCard {
  display: flex;
  flex-direction: column;
  position: relative;
}

.filterCard p {
  padding: 28px 0px 0px;
  position: absolute;
}

.filterCard .form-group .form-control:focus {
  border-color: #139ff0;
  box-shadow: none;
}

.filterCard .categories,
.RequirementList label {
  display: none;
}

.filterCard .form-check label {
  font-weight: 400;
}

.filterCard .filterCheck {
  margin: 1rem 0rem;
}

.clientreqCard .list-group .list-group-item img {
  width: 12rem;
  height: 12rem;
  float: left;
}

.clientreqCard .list-group .list-group-item h1 {
  font-size: 22px;
  line-height: 1.43;
  font-weight: 500;
  font-style: normal;
  color: #000;
}

.clientreqCard .list-group .list-group-item p {
  font-size: 16px;
  line-height: 1.43;
  font-weight: 400;
  font-style: normal;
  color: #000;
  height: 50px;
  overflow: hidden;
}

.clientreqCard .list-group .list-group-item h1 span {
  padding-left: 10px;
}

.categorylist ul {
  display: flex;
  list-style: none;
  padding: 0;
}

.categorylist ul li {
  display: flex;
  list-style: none;
  padding: 4px;
}

.cardButtons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 180px;
}

.cardButtons h6 {
  font-size: 22px;
  line-height: 1.43;
  font-weight: 500;
  font-style: normal;
  color: #000;
}
.cardButtons .btn-hire {
  color: #fff;
  background-color: #f20091;
  border: 1px solid #f20091;
  padding: 0.3rem 0.7rem;
  font-size: 0.99rem;
  border-radius: 0.25rem;
  font-weight: 600;
  width: 150px;
  margin-right: 10px;
}
.proposalBid {
  display: flex;
  justify-content: space-evenly;
  width: 200px;
  background-color: #fff;
}

.proposalBid div {
  background-color: #fff;
  box-shadow: 0 0 6px #818181;
  width: 100px;
  text-align: center;
}
.proposalBid div h1 {
  font-size: 22px;
  line-height: 1.43;
  font-weight: 500;
  font-style: normal;
  color: #000;
  padding: 0px 10px;
}

.proposalBid div p {
  font-size: 14px;
  line-height: 1.43;
  font-weight: 500;
  font-style: normal;
  color: #000;
  padding: 0px 0px;
}

.RequirementList .wrapper-skill-content ul {
  border: none;
  padding: 0px;
  margin: 0px;
}

.RequirementList p {
  font-size: 14px;
  line-height: 1.43;
  font-weight: 400;
  font-style: normal;
  color: #000;
}

.rqlView {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.rqlView h1 {
  font-size: 22px;
  line-height: 1.43;
  font-weight: 700;
  font-style: normal;
  color: #000;
  align-self: flex-start;
}

.rqlView p {
  font-size: 16px;
  line-height: 1.43;
  font-weight: 400;
  font-style: normal;
  color: #000;
  align-self: center;
}

.RequirementList .form-group input {
  border-color: #9b9b9b;
}

.filter .form-group input {
  border-color: #9b9b9b;
}

.RequirementList .form-group textarea {
  border-color: #9b9b9b;
}
.RequirementList .form-group textarea:focus {
  border-color: #007fed;
}

.RequirementList .form-group input:focus {
  border-color: #007fed;
}

.filter .form-group input:focus {
  border-color: #007fed;
}

.bidding {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bidding label {
  display: block;
  padding: 10px;
}

.btn-req {
  color: #fff;
  background-color: #fa4052;
  border: 1px solid #fa4052;
  padding: 0.3rem 0.7rem;
  font-size: 0.99rem;
  border-radius: 0.25rem;
  margin-top: 0.8rem;
  font-weight: 600;
  width: 220px;
  margin-right: 10px;
}

.btn-req:hover {
  background-color: #fa4052;
}

.clientview {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  position: relative;
  height: 632px;
}

.clientview img {
  width: 100%;
  height: 50vh;
}

.client-card-header img {
  width: 16rem;
  height: 16rem;
  padding: 16px;
}

.clientview .container {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
}

.client-card-details {
  border: #818181;
  background-color: #fff;
  box-shadow: 0 0 6px #818181;
  border-radius: 6px;
}

.client-card-header {
  padding: 18px;
}

.client-card-header h1 {
  font-size: 32px;
  line-height: 1.43;
  font-weight: 700;
  font-style: normal;
  color: #000;
}

.client-card-header h3 {
  font-size: 22px;
  line-height: 1.43;
  font-weight: 700;
  font-style: normal;
  color: #000;
}

.client-card-header p {
  font-size: 16px;
  line-height: 1.43;
  font-weight: 400;
  font-style: normal;
  color: #000;
  text-align: justify;
}

.clientreqCard .list-group .list-group-item:hover {
  background-color: #c9c9c5;
}

.steps {
  border: 1px solid #e7e7e7;
}

.steps-header {
  padding: 0.375rem;
  border-bottom: 1px solid #e7e7e7;
}

.steps-header .progress-bar {
  background-color: #926bac;
}

.steps-header .progress {
  height: 0.25rem;
}

.steps-body {
  display: table;
  table-layout: fixed;
  width: 100%;
  cursor: pointer;
}

.step {
  display: table-cell;
  position: relative;
  padding: 1rem 0.75rem;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  text-align: center;
  text-decoration: none;
}

.step:last-child {
  border-right: 0;
}

.step-indicator {
  display: block;
  position: absolute;
  top: 0.75rem;
  left: 0.75rem;
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid #e7e7e7;
  border-radius: 50%;
  background-color: #fff;
  font-size: 0.875rem;
  line-height: 1.375rem;
}

.has-indicator {
  padding-right: 1.5rem;
  padding-left: 2.375rem;
}

.has-indicator .step-indicator {
  top: 50%;
  margin-top: -0.75rem;
}

.step-icon {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 auto;
  margin-bottom: 0.75rem;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  color: #888;
}

.step:hover {
  color: rgba(0, 0, 0, 0.9);
  text-decoration: none;
}

.step:hover .step-indicator {
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  border-color: transparent;
  background-color: #f4f4f4;
}

.step:hover .step-icon {
  color: #926bac;
}

.step-active,
.step-active:hover {
  color: #fff;
  pointer-events: none;
  cursor: default;
  background: #926bac;
}

.step-active .step-indicator,
.step-active:hover .step-indicator {
  border-color: transparent;
  background-color: #926bac;
  color: #fff;
}

.step-active .step-icon,
.step-active:hover .step-icon {
  color: #fff;
}

.step-completed .step-indicator,
.step-completed:hover .step-indicator {
  border-color: transparent;
  background-color: rgba(51, 203, 129, 0.12);
  color: #33cb81;
  line-height: 1.25rem;
}

.step-completed .step-indicator .feather,
.step-completed:hover .step-indicator .feather {
  width: 0.875rem;
  height: 0.875rem;
}

.checkoutstepper {
  top: 36px;
  right: 0;
  position: relative;
  left: 0;
}

.settings .nav-link {
  border-radius: 0px;
  background-color: #fff !important;
  color: #000 !important;
  width: 15rem;
  text-align: left;
  padding: 10px;
}

.settings .nav .active {
  background-color: #fff !important;
  color: #007fed !important;
  border-left: 1px solid #007fed;
}

.settings .tab-content {
  width: 100%;
  border: 0 0 6px #dedede;
  background-color: #fff;
  box-shadow: 0 0 6px #dedede;
  padding: 20px 20px;
}

.settings .tab-content .form-group input {
  border: 1px solid #9b9b9b;
}

.settings .tab-content button {
  margin: 10px;
}

.settings .tab-content h1 {
  font-size: 32px;
  line-height: 1.43;
  font-weight: 700;
  font-style: normal;
  color: #000;
}

.settings .tab-content h3 {
  font-size: 22px;
  line-height: 1.43;
  font-weight: 500;
  font-style: normal;
  color: #000;
}

.settings .tab-content p {
  font-size: 16px;
  line-height: 1.43;
  font-weight: 400;
  font-style: normal;
  color: #000;
}

.settings .tab-content label {
  font-size: 16px;
  line-height: 1.43;
  font-weight: 400;
  font-style: normal;
  color: #000;
  padding: 10px 0px;
}

.settings .tab-content .form-group input:focus {
  border-color: #139ff0;
  box-shadow: none;
}

.settings .nav .fa {
  width: 25px;
}

.tab-1 .form-group {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.tab-1 .form-group div:nth-child(1) {
  width: 50%;
}

.tab-1 .form-group div:nth-child(2) {
  width: 50%;
}

.tab-1 .form-group div:nth-child(2) .alert-danger {
  width: 100% !important;
}

.tab-1 .form-group label {
  width: 100%;
}

.checkout-button {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: flex-end;
}

.checkout-button button {
  color: #fff;
  background-color: #926bac;
  border: 1px solid #fff;
  padding: 0.3rem;
  font-size: 14px;
  border-radius: 0.25rem;
  font-weight: 600;
  width: 150px;
}

.membership-plan {
  padding: 14px;
  text-align: center;
  border: 0 0 6px #dedede !important;
  background-color: #fff;
  box-shadow: 0 0 6px #dedede;
}

.membership-card {
  display: flex;
  justify-content: center;
  align-items: center;
}

.membership-plan .fa {
  font-size: 32px;
  line-height: 1.43;
  font-weight: 400;
  font-style: normal;
  color: #000;
}

.membership-plan h1 {
  font-size: 32px;
  line-height: 1.43;
  font-weight: 400;
  font-style: normal;
  color: #000;
}

.membership-plan p {
  font-size: 20px;
  line-height: 1.43;
  font-weight: 400;
  font-style: normal;
  color: #000;
}

.membership-plan p > span {
  font-size: 10px;
}

.membership-plan h3 {
  font-size: 16px;
  line-height: 1.43;
  font-weight: 400;
  font-style: normal;
  color: #000;
  padding: 14px;
}

.membership-card button {
  color: #fff;
  background-color: #108fdf;
  border: 1px solid #fff;
  border-radius: 25px !important;
  padding: 0.3rem 0.7rem;
  font-size: 0.99rem;
  border-radius: 0.25rem;
  font-weight: 600;
  width: 150px;
}

.membership-plan .logo {
  width: 100%;
  height: 100px;
  padding: 0rem 2rem;
}

.membership-plan .logo img {
  width: 100%;
  height: 100%;
}

.checkout-button button i {
  width: 25px;
}

.user-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.user-profile img {
  width: 14rem;
  border: 1px solid #28ddf1;
  border-radius: 50%;
  padding: 7px;
}

.user-profile .active {
  background: #02bdab;
}

.user-profile h3 {
  padding: 20px;
}

.btn-continue {
  color: #fff;
  background-color: #ffb41b;
  border: 1px solid #fff;
  padding: 0.3rem 0.7rem;
  font-size: 0.99rem;
  border-radius: 0.25rem;
  font-weight: 600;
  width: 150px;
}

.btn-continue:hover,
.btn-continue:focus {
  color: #fff;
  background-color: #ffb41b;
}

.invoice {
  padding: 30px;
}

.invoice h2 {
  margin-top: 0px;
  line-height: 0.8em;
}

.invoice .small {
  font-weight: 300;
}

.invoice hr {
  margin-top: 10px;
  border-color: #ddd;
}

.invoice .table tr.line {
  border-bottom: 1px solid #ccc;
}

.invoice .table td {
  border: none;
}

.invoice .identity {
  margin-top: 10px;
  font-size: 1.1em;
  font-weight: 300;
}

.invoice .identity strong {
  font-weight: 600;
}

.grid {
  position: relative;
  width: 100%;
  background: #fff;
  color: #666666;
  border-radius: 2px;
  margin-bottom: 25px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}

.grid-body {
  padding: 2rem;
}

.chat-content {
  margin: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

.chat-content .container {
  width: 500px;
  margin: auto;
}

aside.EmojiPickerReact.epr-main {
  height: 300px !important;
  width: 100% !important;
}

.chat {
  display: flex;
  flex-direction: column;
  background: #f1f0e8;
  background: url("../../components/assets/images/bg.jpeg");
}
.chat-header {
  display: flex;
  cursor: pointer;
}
.profile {
  width: 100%;
  background: #036055;
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0px 10px;
  position: relative;
}
.profile .pp {
  width: 50px;
  display: inline-block;
  border-radius: 50%;
  margin-left: 32px;
}
.profile .arrow {
  display: inline-block;
  width: 30px;
  position: absolute;
  top: 19px;
  cursor: pointer;
  left: auto;
}
.profile h2 {
  display: inline-block;
  line-height: 48px;
  vertical-align: bottom;
  color: #fff;
  margin-left: 6px;
  font-size: 20px;
}
.profile span {
  color: #ccc;
  position: absolute;
  top: 42px;
  left: 100px;
  font-size: 14px;
}
.right .icon {
  display: inline-block;
  width: 25px;
  margin-left: 10px;
}
.profile .left {
  flex: 1;
}

.chat-box {
  background-attachment: fixed;
  padding: 20px;
  overflow: auto;
  height: 75vh;
}
.chat-box .img_chat {
  width: 280px;
}

.chat-r {
  display: flex;
}
.chat-r .sp {
  flex: 1;
}

.chat-l {
  display: flex;
}
.chat-l .sp {
  flex: 1;
}

.chat-box .mess {
  max-width: 300px;
  background: #f7fcf6;
  padding: 10px;
  border-radius: 10px;
  margin: 20px 0px;
  cursor: pointer;
}
.chat-box .mess p {
  font-size: 18px;
  text-align: justify;
}

.chat-box .mess-r {
  /* background: #E2FFC7; */
  background: linear-gradient(135deg, rgb(255, 78, 111), rgb(251, 145, 104));
  color: #fff;
}
.chat-box .emoji {
  width: 20px;
}
.chat-box .check {
  float: right;
}
.chat-box .check img {
  width: 20px;
}
.chat-box .check span {
  color: #888;
  font-size: 12px;
  font-weight: 700px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
}
*::-webkit-scrollbar-thumb {
  background: #aaa;
}
*::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* style the scrollbar track */
.chat-box::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* style the scrollbar thumb */
.chat-box::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

/* change the width of the scrollbar */
.chat-box::-webkit-scrollbar {
  width: 10px;
}

.chat-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  position: relative;
  cursor: pointer;
}
.chat-footer input {
  display: block;
  flex: 1;
  width: 100%;
  height: 50px;
  border-radius: 60px;
  margin: 5px;
  padding: 10px;
  outline: none;
  font-size: 19px;
  padding-left: 40px;
  padding-right: 90px;
  border: 2px solid #ccc;
  color: #555;
  resize: none;
}
.chat-footer .mic {
  display: block;
  width: 55px;
  height: 55px;
  margin-right: 20px;
}

.chat-footer .emo {
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 10px;
  top: 12px;
}
.chat-footer .icons {
  position: absolute;
  right: 100px;
  top: 10px;
}
.chat-footer .icons img {
  display: inline-block;
  width: 35px;
  height: 35px;
  margin-left: 5px;
}

.chat-list .secContainer {
  overflow: auto;
  padding: 20px;
}

.chat-panel {
  background-color: #fff;
}

.payment {
  background-color: #fff;
  height: 100vh;
}

.payment .logo {
  height: 100px;
  display: block;
  text-indent: -9999px;
  padding: 30px;
}

.payment .darkWeb {
  height: 50vh;
  display: block;
  text-indent: -9999px;
  border-radius: 3rem;
}

.payment .message,
.payment .details {
  padding: 28px;
  border: 0 0 6px #dedede !important;
  box-shadow: 0 0 6px #dedede;
  background-color: #ededed;
  display: flex;
  flex-direction: column;
}

.payment h3 {
  font-size: 2rem;
  line-height: 1.43;
  font-weight: 700;
  font-style: normal;
  color: #000;
}

.payment p {
  font-size: 1rem;
  line-height: 1.43;
  font-weight: 400;
  font-style: normal;
  color: #000;
}

.payment button {
  display: flex;
  width: 200px;
  padding: 8px 50px !important;
  align-self: end;
}

.payment .details .form-group {
  display: flex;
  padding: 12px 0px;
  justify-content: space-between;
}

.payment .details .form-group label {
  display: flex;
  font-size: 1.25rem;
  line-height: 1.43;
  font-weight: 500;
  font-style: normal;
  color: #000;
}

.details .form-group input {
  border: 1px solid #9b9b9b;
}

.details .form-group input:focus {
  border: 1px solid #007fed;
}

.starRating {
  background: inherit;
  border: 0;
}

.starRating input[type="radio"] {
  display: none;
}

.star {
  cursor: pointer;
  transition: color 200ms;
}

.btn-forward,
.btn-backward {
  width: 45px;
  height: 45px;
  color: white;
  background-color: #000026;
  border-radius: 50%;
  margin: 4px;
  font-size: 22px;
  box-shadow: none;
  font-weight: 700;
}

.btn-forward:hover,
.btn-backward:hover {
  color: white;
  background-color: #000026;
}

.adminDiv .dropdown-menu.show {
  display: block;
  position: absolute;
  right: 0;
  padding: 7px;
}

.hh-grayBox {
  background-color: #f8f8f8;
  margin-bottom: 20px;
  padding: 25px;
  margin-top: 20px;
}
.pt45 {
  padding-top: 45px;
}

.order-tracking {
  text-align: center;
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}

.order-tracking .is-complete {
  display: block;
  position: relative;
  border-radius: 50%;
  height: 30px;
  cursor: pointer;
  width: 30px;
  border: 0px solid #afafaf;
  background-color: #f7be16;
  margin: 0 auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
  z-index: 1;
}
.order-tracking .is-complete:after {
  display: block;
  position: absolute;
  content: "";
  height: 14px;
  width: 7px;
  top: -2px;
  bottom: 0;
  left: 5px;
  margin: auto 0;
  border: 0px solid #afafaf;
  border-width: 0px 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
}
.order-tracking.completed .is-complete {
  border-color: #27aa80;
  border-width: 0px;
  background-color: #27aa80;
}
.order-tracking.completed .is-complete:after {
  border-color: #fff;
  border-width: 0px 3px 3px 0;
  width: 7px;
  left: 11px;
  opacity: 1;
}
.order-tracking p {
  color: #000;
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 0;
  line-height: 20px;
  text-align: left;
}

.order-tracking .order-status {
  color: #000;
  font-size: 16px;
  padding: 0px 10px;
  line-height: 20px;
  text-align: left;
  width: 85%;
  height: 25vh;
}
.order-tracking .order-status textarea {
  border-color: #139ff0;
  box-shadow: none;
}

.postBid input.form-check-input {
  margin-top: 12px;
}

.order-tracking .order-image {
  width: 25%;
  height: 25vh;
}
.order-tracking .order-image .btn-attach {
  width: 8rem;
  font-size: 14px;
}

.order-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 5%;
}

.order-tracking p span {
  font-size: 14px;
}
.order-tracking.completed p {
  color: #000;
}
.order-tracking::before {
  content: "";
  display: block;
  height: 100%;
  width: 3px;
  background-color: #ffffff;
  top: -90px;
  position: absolute;
  left: 16px;
  z-index: 0;
}

.order-tracking:first-child:before {
  display: none;
}
.order-tracking.completed:before {
  background-color: #27aa80;
}

.table-attach td:nth-child(1) {
  width: 30px;
}

.bRequirementList .select select option:nth-child(1) {
  display: none;
}
.payment-form .select select option:nth-child(1) {
  display: none;
}

.order-tracking p:nth-child(3) {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  font-size: 14px;
  text-overflow: ellipsis;
  padding: 0;
}

.product-box p {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  padding: 0;
  font-size: 14px;
  text-overflow: ellipsis;
}

.foot-box ul li {
  cursor: pointer;
}

.policy-box {
  padding-top: 7rem;
}
.policy-box h1 {
  color: #fff;
  font-size: 25px;
  background-color: #ea1d1c;
  padding: 0rem 1rem;
  padding: 0.4rem 1rem;
}
.policy-box p {
  font-size: 18px;
  text-align: justify;
  color: #000;
}
.policy-box h4 {
  font-size: 21px;
  font-weight: 500;
}
.policy-box ul li {
  font-size: 17px;
}
.main-box p {
  font-size: 18px;
  text-align: justify;
  color: #000;
  margin-bottom: 1.6rem;
}
.box p {
  font-size: 18px;
  text-align: justify;
  color: #000;
}
.box {
  padding-bottom: 8rem;
}
.policy-box p a {
  font-weight: 600;
}

.editor {
  border: 1px solid #afafaf;
  height: 40vh;
  overflow: hidden;
}

.rdw-editor-main {
  height: 30vh !important;
  overflow-y: scroll !important;
  box-sizing: border-box !important;
}

.img-upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  border: 1px solid #926bac;
}

.img-upload img {
  width: 100px;
  height: 100px;
}

.upload-button {
  border-top: 1px solid #926bac;
  width: 100%;
}

.upload-button button {
  width: 100%;
  background-color: #926bac;
  color: #fff !important;
}

.upload-button button:hover {
  background-color: #9e4fe9;
  color: #fff !important;
}

.tab-1 form {
  position: relative;
}

.carousel-inner img {
  height: 100%;
  padding: 3rem 0px 38px 0px;
  width: 100% !important;
}
.carousel-item {
  height: 80vh;
  padding: 12px 0px 36px;
  background-color: #fe919b29;
}

.new_login_panel {
  height: 100vh !important;
  width: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    122deg,
    rgba(150, 144, 213, 1) 0%,
    rgba(239, 165, 206, 1) 100%
  );
}

.img-admin-upload {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  flex-direction: row-reverse;
}

.carousel-indicators {
  display: none;
}

.btn-filter {
  display: none;
}

.col-md-4 .filter {
  display: block;
}

.desktop-chat {
  display: flex;
}

.mobile-chat {
  display: none;
}

.back {
  display: none;
}

.about-us-sec h3 {
  padding-bottom: 2rem;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
}

.contact-details {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
  font-size: 100%;
  padding-bottom: 2rem;
  padding-top: 3rem;
  background-color: #fe919b29;
}

.sec-title {
  position: relative;
  margin-bottom: 40px;
}

.sec-title .sub-title {
  position: relative;
  top: -8px;
  font-size: 14px;
  color: #3d69b3;
  line-height: 1.2em;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.sec-title .sub-title:before {
  position: absolute;
  left: 100%;
  top: 50%;
  height: 2px;
  width: 40px;
  margin-left: 10px;
  margin-top: -1px;
  background: #3d69b3;
  content: "";
}

.sec-title h1 {
  position: relative;
  font-size: 60px;
  line-height: 1em;
  color: #222429;
  font-family: inherit;
  font-weight: 700;
}

.sec-title h2 {
  position: relative;
  font-size: 48px;
  color: #222429;
  font-family: inherit;
  font-weight: 700;
  letter-spacing: 0.01em;
  margin-top: -5px;
  margin-bottom: 0;
}

.sec-title .text {
  margin-top: 30px;
  color: #808287;
  font-size: 16px;
  font-family: inherit;
  font-weight: 400;
  line-height: 30px;
}

.sec-title.light h2,
.sec-title.light h1 {
  color: #fff;
}

.sec-title.light .text {
  color: #8c8f94;
}

.sec-title.light .sub-title {
  color: #fe4c48;
}

.sec-title.light h2,
.sec-title.light h1 {
  color: #fff;
}

.sec-title.light .text {
  color: #8c8f94;
}

.sec-title.light .sub-title.light {
  color: #fff;
}

.sec-title.light .sub-title.light:before {
  content: none;
}

contact-details__info {
  position: relative;
  display: block;
  margin-top: 41px;
}

.contact-details__info li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.contact-details__info li .icon {
  height: 60px;
  width: 15%;
  background-color: #222429;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.contact-details__info li .icon i {
  color: #fff;
  font-size: 25px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.contact-details__info li:hover .icon {
  background-color: #fe4c48;
}

.contact-details__info li:hover .icon i {
  color: #164333;
}

.contact-details__info li:hover .text a {
  color: var(--notech-base);
}

.contact-details__info li:hover .text a span {
  color: var(--notech-gray);
}

.contact-details__info li .text {
  margin-left: 30px;
  width: 85%;
}

.contact-details__info li .text p {
  font-size: 14px;
  line-height: 24px;
}

.contact-details__info li .text a {
  font-size: 18px;
  color: var(--notech-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.contact-details__info li .text span {
  font-size: 17px;
  color: var(--notech-black);
}

.contact-details__info li + li {
  margin-top: 19px;
}

.contact_form .form-control,
.input-text {
  height: calc(1.25rem + 20px);
  padding: 10px 21px;
  outline: 0;
  background-color: #f4f5f8;
  border: 1px solid #f4f5f8;
  color: #686a6f;
  font-size: 0.9rem;
  width: 100%;
}

.contact_form .form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.contact_form textarea.form-control {
  height: auto;
  padding-top: 15px;
  padding-bottom: 15px;
}

.contact_form select {
  height: calc(1.25rem + 20px);
  padding: 10px 21px;
  outline: 0;
  background-color: #f4f5f8;
  border: 1px solid #f4f5f8;
  color: #686a6f;
  font-size: 0.9rem;
  width: 100%;
}

.contact_form .form-group div:nth-child(1) {
  display: none;
}

.btn-style-one {
  position: relative;
  font-size: 13px;
  line-height: 24px;
  padding: 7px 14px;
  font-weight: 700;
  letter-spacing: 0.1em;
  overflow: hidden;
  text-transform: uppercase;
  color: #fff;
  border: 0;
  background: #000026;
}

.modal-dialog.lead .modal-body {
  background: #272a42;
}
.lead-div {
  position: fixed;
  bottom: 5rem;
  right: 1.5rem;
  z-index: 1;
  border-radius: 50%;
  line-height: 24px;
  padding: 14px;
  border: 0;
  background: #000026;
}

.lead-div:active {
  background: #000026 !important;
}

.lead-div:hover {
  background: #000026 !important;
}

@media (max-width: 575.98px) {
  .steps-header {
    display: none;
  }
  .steps-body,
  .step {
    display: block;
  }
  .step {
    border-right: 0;
    border-bottom: 1px dashed #e7e7e7;
  }
  .step:last-child {
    border-bottom: 0;
  }
  .has-indicator {
    padding: 1rem 0.75rem;
  }
  .has-indicator .step-indicator {
    display: inline-block;
    position: static;
    margin: 0;
    margin-right: 0.75rem;
  }
}

/**responsive**/

@media (max-width: 1500px) and (min-width: 1200px) {
  .light-t .offcanvas.offcanvas-start {
    display: none;
  }

  .navbar-bot {
    display: none;
  }
}
@media (max-width: 1200px) and (min-width: 992px) {
  /** for-laptop-view**/

  /**index-page**/

  .container {
    max-width: 980px;
  }
  .light ul li {
    margin-left: 2rem;
    font-size: 9px;
  }
  .light ul {
    margin-left: 1rem;
  }
  .light-t .offcanvas.offcanvas-start {
    display: none;
  }
  .navbar-bot {
    display: none;
  }
}
/** tablet-view**/

@media (max-width: 991px) and (min-width: 768px) {
  .container {
    max-width: 720px;
  }
  .light ul div {
    display: block;
  }
  .product-img {
    padding: 0px 0rem;
  }

  .navbar-bot {
    background: linear-gradient(
      111.21deg,
      #9e4fe9 -2.4%,
      #e14fa9 46.51%,
      #c92f9f 93.12%
    );
    height: 10vh;
    z-index: 1;
  }

  .navbar-bot ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
  }

  .navbar-bot ul a {
    display: grid;
    width: fit-content;
    padding: 8px;
    color: #fff;
    text-align: center;
  }
  .navbar-bot .bottom-link {
    width: 100%;
  }
}

@media (max-width: 2560px) and (min-width: 1440px) {
  .light-t .offcanvas {
    display: none;
  }
  .navbar-bot {
    display: none;
  }
}

@media (max-width: 768px) and (min-width: 580px) {
  .container {
    max-width: 540px;
  }
  .navbar-bot {
    display: flex;
  }
  .product-img {
    padding: 0px 0rem;
  }

  .navbar-bot {
    background: linear-gradient(
      111.21deg,
      #9e4fe9 -2.4%,
      #e14fa9 46.51%,
      #c92f9f 93.12%
    );
    height: 10vh;
    z-index: 1;
  }

  .navbar-bot ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
  }

  .navbar-bot ul a {
    display: grid;
    width: fit-content;
    padding: 8px;
    color: #fff;
    text-align: center;
  }
  .navbar-bot .bottom-link {
    width: 100%;
  }
}

@media (max-width: 579px) and (min-width: 280px) {
  .policy-box {
    padding-top: 0rem;
  }
}

@media screen and (max-width: 480px) {
  .api-in {
    top: 3rem;
    left: 2rem;
  }

  .api-in h3 {
    width: 16rem;
    font-size: 1.5rem;
  }

  .api-in p {
    width: 16rem;
  }

  .back {
    display: block;
  }

  .bg-video-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bg-video-wrap img {
    display: block;
    width: 100%;
    height: 85vh;
  }

  .category-row {
    flex-direction: column;
  }

  .col-md-4 .filter {
    display: none;
  }

  .bg-video-wrap .video-content a.a-1 button {
    width: 90%;
  }

  .bg-video-wrap .video-content a.a-2 button {
    margin-left: 0rem;
    margin-top: 1rem;
    width: 90%;
  }

  .desktop-chat {
    display: none;
  }

  .mobile-chat {
    display: flex;
  }

  .bRequirementList h3 {
    display: none;
  }

  .select {
    display: flex;
  }

  .carousel-inner {
    height: 220px;
  }
  .carousel-inner img {
    height: auto !important;
    margin-top: 70px;
    padding: 0px 11px;
  }

  .req {
    flex-direction: column;
  }

  .membership-card .btn-continue {
    display: none !important;
  }

  .foot-box p {
    padding-right: 15px;
  }

  .foot-box-1 {
    margin: 0px -16px;
    padding-top: 0px;
  }

  .btn-filter {
    display: block !important;
  }

  .light-t .container-fluid {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .light-t .navbar-brand {
    margin: unset;
    height: 50px;
  }
  .light-t .offcanvas.offcanvas-start {
    width: 50%;
  }

  .navbar-bot {
    background: linear-gradient(
      111.21deg,
      #9e4fe9 -2.4%,
      #e14fa9 46.51%,
      #c92f9f 93.12%
    );
    height: 10vh;
    z-index: 1;
  }

  .navbar-bot ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
  }
  .app-form {
    background-color: #fff;
    padding: 20px 20px;
  }

  .login_right_sec {
    height: auto;
  }
  .app-packaging-logo {
    display: none;
  }

  .right_login_con {
    display: none;
  }

  .app-form-card .row {
    display: flex;
    flex-direction: column-reverse;
  }
  .app-form-card {
    height: auto;
  }
  /* .new_login_panel {
        height: 100% !important;
    } */
  .navbar-bot ul a {
    width: fit-content;
    padding: 8px;
    color: #fff;
    text-align: center;
  }

  .btn-attach {
    width: auto;
    float: unset;
  }

  .different-categories-sec .col {
    width: 33%;
  }

  .diff-img {
    width: 7rem;
  }

  .great-section,
  .make-real-section,
  .different-categories-sec {
    padding: 4rem 2rem 4rem 2rem;
  }

  .great-section h1 {
    font-size: 2rem;
  }

  .different-categories-sec h1 {
    font-size: 2rem;
  }

  .light {
    height: auto;
  }

  .light-line {
    display: none;
  }

  .make-real-section h1 {
    font-size: 2rem;
  }

  .post-project {
    height: 50vh;
  }

  .post-project-header {
    padding: 0;
  }
  .post-project-body {
    padding: 0;
    margin: 6% 0px;
  }

  .post-project-body .post-content {
    padding: 0rem;
  }

  .post-medium-content {
    padding: 18px 20px;
  }

  .post-medium-content img {
    width: auto !important;
    padding: 0%;
    float: left;
    margin-right: 24px;
  }
  h5.heading {
    text-align: left;
  }

  p.paragraph {
    text-align: left;
  }

  .bottom-footer {
    margin-bottom: 4rem;
  }

  .bottom-f p {
    text-align: center;
    float: none !important;
  }

  .bg-video-wrap .video-content {
    top: 2rem;
    left: 2rem;
  }

  .bg-video-wrap .video-content h1 {
    font-size: 2rem;
    width: 16rem;
  }

  .bg-video-wrap .video-content ul {
    padding-left: 2rem;
  }
  .overlay {
    display: block;
  }

  .light ul div {
    display: block;
  }

  .footer-top-content ul li a .fa-span {
    padding-left: 0px;
  }

  .footer-top-content img {
    width: 60%;
  }
}

@media (max-width: 1200px) and (min-width: 992px) {
  /** for-laptop-view**/

  /**index-page**/

  .container {
    max-width: 980px;
  }
  .light-t .navbar-brand img {
    width: 6rem;
  }
  .light-t .navbar-nav .nav-link {
    padding-right: 14px;
    font-size: 14px;
    font-weight: 600;
  }
  .light-t #navbarSupportedContent {
    margin-left: 16rem;
  }
  .light-t button {
    border-radius: 20px;
    padding: 7px 26px;
    margin-top: 12px;
  }
  .t-1 a i {
    top: -2px;
    left: 10px;
    padding: 9px 10px;
  }
  li.t-1 span {
    top: -7px;
    left: 36px;
    font-size: 12px;
    padding: 1px 11px;
  }
  .home-b-1 p {
    font-size: 14px;
  }
  .about-us-sec {
    padding-top: 6rem;
  }
  .about-box p {
    font-size: 14px;
  }
  .slide-1 button {
    padding: 8px 43px;
    border-radius: 20px;
  }
  .our-clients .row {
    padding: 0rem 4rem;
  }
  .foot-box-1 p {
    width: 18rem;
  }
  .row-2-b h5 {
    font-size: 18px;
  }
  .row-2-b p {
    font-size: 14px;
  }
  .row-1 {
    top: 23.3rem;
    left: 0rem;
  }
  .foot-box h4 {
    font-size: 21px;
  }
  .foot-box-1 h4 {
    font-size: 21px;
  }
  .foot-box a {
    padding: 6px;
  }
  .num-user .row {
    margin: 0px 15px 0px 15px;
  }
  .about-box h3 {
    padding-bottom: 15px;
  }
  .swiper-button-prev {
    left: 6rem;
    right: auto;
    top: 27rem;
  }
  .slider-why .swiper-button-next {
    right: 10px;
    left: 10rem;
    top: 27rem;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .container {
    max-width: 720px;
  }

  .btun {
    margin-top: 40px;
  }
  .light-t #navbarSupportedContent {
    margin-left: 1rem;
    margin-top: 1rem;
  }
  .num-user .row {
    margin: 25px 0px 0px 0px;
  }
  .home-b-1 i {
    margin-right: 0px;
    margin-left: -3.5rem;
    padding: 12px 13px;
  }

  .home-b-1 h3 {
    font-size: 21px;
  }
  .home-b-1 p {
    font-size: 11px;
  }
  .home-b-1 {
    margin-left: 4rem;
  }
  .about-us-sec {
    padding-top: 5rem;
  }
  .about-us-sec .row {
    padding: 0rem 2rem;
  }
  .about-us-sec h3 {
    text-align: center;
    font-size: 25px;
    padding-bottom: 10px;
  }
  .about-box p {
    text-align: justify;
    font-size: 12px;
  }
  .slider-sec h1 {
    font-size: 35px;
  }
  .slide-1 {
    padding: 10px 25px 10px 20px;
    margin: 0rem 0rem 0rem 0rem;
  }
  .slide-1 h2 {
    font-size: 20px;
  }
  .slide-1 p {
    font-size: 12px;
  }
  .slide-1 button {
    padding: 8px 25px;
    border-radius: 20px;
    font-size: 12px;
  }
  .b {
    font-size: 18px;
    padding: 15px 16px;
    margin-bottom: 1rem;
  }
  .cate-section h1 {
    padding-bottom: 2rem;
    font-size: 35px;
  }
  .cate-section .row {
    padding: 0rem 5rem;
  }
  .cat-1 {
    padding: 50px 26px;
  }
  .cate-section {
    padding-bottom: 6rem;
  }
  .product-box {
    padding: 2rem 5rem 0rem 3rem;
  }
  .clients-img {
    padding: 0px 10px;
  }
  .our-clients .row {
    padding: 0rem 3rem;
  }
  .reviews {
    padding: 5rem 0rem 4rem 0rem;
  }
  .foot-box img {
    width: 8rem;
  }
  .foot-box p {
    font-size: 13px;
  }
  .foot-box-1 h4 {
    font-size: 15px;
  }
  .foot-box h4 {
    font-size: 15px;
  }
  .foot-box-1 p {
    width: 11rem;
    font-size: 14px;
  }
  .foot-box ul li {
    line-height: 1.4rem;
    font-size: 14px;
  }
  .foot-box a i {
    font-size: 14px;
  }
  .foot-box a {
    color: #fff;
    padding: 2px;
  }
  .row-2-b {
    padding: 20px 0px 2px 10px;
  }
  .row-2-b h5 {
    font-size: 16px;
  }
  .row-2-b p {
    font-size: 11px;
  }
  .row-1 {
    top: 24rem;
    left: 0rem;
  }
  .bottom-f p {
    font-size: 14px;
  }
}

@media (max-width: 767px) and (min-width: 580px) {
  .container {
    max-width: 540px;
  }
  .light-t #navbarSupportedContent {
    margin-left: 1rem;
    margin-top: 1rem;
  }
  .btun {
    margin-top: 41px;
  }
  .num-user .row {
    margin: 0px 0px 0px 0px;
    top: 2rem;
  }
  .about-us-sec {
    padding-top: 6rem;
  }
  .about-us-sec .row {
    padding: 0rem 1rem;
  }
  .about-box h3 {
    padding-bottom: 16px;
  }
  .slider-sec {
    padding-top: 4rem;
    padding-bottom: 2rem;
  }
  .slide-1 button {
    padding: 8px 124px;
  }

  .slide-1 {
    margin-bottom: 2rem;
  }
  .cate-section .row {
    padding: 0rem 6rem;
  }
  .cat-1 {
    padding: 23px 85px;
    margin-bottom: 2rem;
  }
  .cate-section {
    padding-bottom: 4rem;
  }
  .product-box p {
    margin-bottom: 5rem;
  }
  .product-box {
    padding: 2rem 12rem 0rem 4rem;
    color: #fff;
  }
  .product-img {
    border-radius: 80px 0px 0px 0px;
    margin-top: -2.3rem;
    margin-left: 0;
  }
  .product-img img {
    border-radius: 80px 0px 0px 0px;
  }
  .our-clients {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .our-clients .col-md-2 {
    width: 33%;
  }
  .our-clients .row {
    padding: 0rem 3rem;
  }
  .reviews {
    padding: 4rem 0rem 4rem 0rem;
  }
  .footer-section {
    padding: 1.5rem 0rem 9rem 0rem;
  }
  .foot-box img {
    width: 7rem;
  }
  .row-1 {
    top: 30.5rem;
    left: 0rem;
  }
  .row-2-b {
    padding: 8px 0px 2px 17px;
  }
  .light-t {
    background-color: #fff;
    padding: 12px 0px;
  }
}
@media (max-width: 579px) and (min-width: 280px) {
  .light-t #navbarSupportedContent {
    margin-left: 0.4rem;
    margin-top: 0.5rem;
  }
  .light-t .navbar-brand img {
    width: 6rem;
  }
  .light-t {
    padding: 2px 0px;
  }
  .swip-box p {
    padding: 0;
  }

  .btun {
    margin-top: 40px;
  }
  .num-user .row {
    margin: 0px 0px 0px 0px;
  }
  .num-user .row {
    top: 1rem;
    border-radius: 12px;
    padding-top: 1rem;
    margin: 0px 0px 0px 0px;
  }
  .home-b-1 {
    margin-left: 2.5rem;
    width: 120px;
  }
  .home-b-1 p {
    font-size: 14px;
    margin-right: 2rem;
    font-weight: 500;
    width: 100%;
  }

  .about-img {
    height: 100%;
    padding: 8px;
  }
  .about-us-sec {
    padding-top: 3rem !important;
  }
  .about-us-sec .row {
    padding: 0rem 1rem;
  }
  .about-box p {
    font-size: 14px;
    padding: 0;
  }
  .about-box h3 {
    font-size: 26px;
    padding-bottom: 9px;
  }
  .slider-sec {
    padding-top: 3rem;
    padding-bottom: 2rem;
  }
  .slider-sec h1 {
    padding-bottom: 2rem;
    font-size: 35px;
  }
  .slider-sec .row {
    padding: 0rem 1rem;
  }
  .slide-1 button {
    padding: 8px 40px;
    border-radius: 20px;
  }
  .slide-1 {
    margin: 1rem 0rem;
    height: fit-content;
  }
  .slide-1 i {
    padding: 12px;
  }
  .choose-img {
    padding: 0rem;
    height: 150px;
  }

  .cat-1 {
    padding: 20px 10px 50px;
    height: 200px;
  }
  .cate-section .row {
    padding: 0rem;
  }
  .cate-section {
    padding-bottom: 3rem;
  }
  .product-box {
    padding: 0rem 4rem 1.6rem 4rem;
  }
  .product-box h3 {
    padding-bottom: 2rem;
  }
  .product-img {
    padding: 1rem 4rem;
  }
  .our-clients {
    padding-top: 1rem;
    padding-bottom: 2rem;
  }
  .our-clients h1 {
    font-size: 26px;
    padding: 16px 0px;
  }
  .our-clients p {
    padding-top: 0rem;
  }
  section.our-clients .col-md-2 {
    width: 33%;
  }
  .footer-section {
    padding: 2.5rem 0rem 6rem 0rem;
  }
  .footer-section .row {
    padding: 0px 18px;
  }
  .foot-box img {
    width: 7rem;
  }
  .foot-box p {
    font-size: 16px;
    margin-top: 10px;
  }
  .foot-box-1 h4 {
    font-size: 20px;
  }
  .foot-box h4 {
    font-size: 20px;
    margin: 2px -15px;
    padding: 0px !important;
  }

  .foot-box ul {
    margin: 0px -25px;
  }
  .row-2-b {
    padding: 7px 0px 1px 13px;
  }
  .row-2-b h5 {
    font-size: 16px;
  }
  .row-2-b p {
    font-size: 14px;
  }
  .footer-section {
    padding: 2.5rem 0rem 2rem 0rem;
  }
  .row-1 {
    position: absolute;
    top: 37.85rem;
  }
}
