@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

.dashboard-body {
    padding: 0;
    box-sizing: border-box;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    transition: .3s ease;
}

/* Colors and Styles (Default) */
:root{
    --PrimaryColor: hsl(94, 59%, 35%);
    --HoverColor: #bdf094; 
    --paleGreen: hsl(96, 75%, 89%);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 18%);
    --textColor: hsl(240, 1%, 48%);
    --bgColor: hsl(220, 10%, 94%);
    --greyText: rgb(98, 98, 98);
    --inputColor: hsl(330, 12%, 97%);

    /* Font ang Typography */
    --biggestFontSize: 2.5rem; 
    --h1FontSize: 1.5rem; 
    --h2FontSize: 1.25rem; 
    --h3FontSize: 1rem; 
    --normalFontSize: .938rem; 
    --smallFontSize: .813rem; 
    --smallestFontSize: .75rem; 
}

/* Default styles */
.dashboard-container {
    font-size: 90%;
}

.dashboard-container a{
    text-decoration: none;
}


.dashboard-body{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 1rem;
}

.dashboard-container{
    position: relative;
    width: 95vw;
    height: 95vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--bgColor);
    overflow: hidden;
    border-radius: 1rem;
    box-shadow: 2px 2px 8px var(--greyText);
}

.dashboard-container li{
    list-style: none;
}

.dashboard-container .icon{
    font-size: var(--h2FontSize);
    cursor: pointer;
}

.dashboard-container img{
    width: 100%;
    height: auto;
}

.flex{
    display: flex;
    align-items: center;
}

.grid{
    display: grid;
    align-items: center;
    gap: 1.5rem;
    padding: 0;
}



.bottom.flex .container {
    padding-left: 0;
}

.bottom.flex .container .form-group {
    padding: 2px 0px;
}

.bottom.flex .container .form-group label {
    padding: 6px 0px;
}

.dashboard-container .btn-submit {
    background-color: #f20091;
    color: #fff;
    border: none;
    padding: 0.8rem 1rem !important;
    float: right;
    bottom: 0;
    position: absolute;
    right: 2rem;
    margin: 1.25rem 0rem;
}

/* Hide the scroll thumb */

.dashboard::-webkit-scrollbar{
    display: none;
}

.dashboard {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.mainContent::-webkit-scrollbar{
    display: none;
}

.mainContent {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.sideBar::-webkit-scrollbar{
    display: none;
}

.sideBar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}


/* // MEDIA QUERIES ==================> */
@media screen and (max-width: 990px) {
    .heading{
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }
}


@media screen and (max-width: 918px) {
    .heading{
        flex-direction: row;
        align-items: center;
        gap: 1rem;
    }
}

@media screen and (max-width: 500px) {
    .heading{
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }
}

