:root {
  --PrimaryColor: hsl(94, 59%, 35%);
  --HoverColor: #bdf094;
  --paleGreen: hsl(96, 75%, 89%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 18%);
  --textColor: hsl(240, 1%, 48%);
  --bgColor: hsl(220, 10%, 94%);
  --greyText: rgb(1, 1, 1);
  --inputColor: hsl(330, 12%, 97%);
  /* Font ang Typography */
  --biggestFontSize: 2.5rem;
  --h1FontSize: 1.5rem;
  --h2FontSize: 1.25rem;
  --h3FontSize: 1rem;
  --normalFontSize: .938rem;
  --smallFontSize: .813rem;
  --smallestFontSize: .75rem;
}

.sideBar {
  background: var(--whiteColor);
  box-shadow: 1px 0px 4px var(--greyText);
  width: 18%;
  height: 100vh;
  gap: 3rem;
  overflow: auto;
}


.sideBar .logoDiv {
  padding: 3.5rem 1.5rem 0;
  cursor: pointer;
}
.sideBar .logoDiv img {
  max-width: 20px;
  margin-right: 0.5rem;
  background: var(--inputColor);
  border-radius: 2px;
  padding: 0.2rem;
}
.sideBar .logoDiv h2 {
  font-size: var(--h2FontSize);
  color: #000;
}
.sideBar .menuDiv .divTitle, .sideBar .settingsDiv .divTitle {
  font-size: var(--h3FontSize);
  color: var(--blackColor);
  padding: 0 1.5rem 1.5rem;
}
.sideBar .menuDiv .menuLists .listItem, .sideBar .settingsDiv .menuLists .listItem {
  padding: 0.2rem 1.5rem;
  width: 100%;
  position: relative;
}
.sideBar .menuDiv .menuLists .listItem::before, .sideBar .settingsDiv .menuLists .listItem::before {
  position: absolute;
  content: "";
  height: 0%;
  left: 0;
  width: 5px;
  bottom: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background: var(--PrimaryColor);
}
.sideBar .menuDiv .menuLists .listItem .menuLink, .sideBar .settingsDiv .menuLists .listItem .menuLink {
  color: #000 !important;
  font-weight: 500;
  transition: 0;
}
.sideBar .menuDiv .menuLists .listItem .menuLink .icon, .sideBar .settingsDiv .menuLists .listItem .menuLink .icon {
  margin-right: 0.5rem;
}
.sideBar .menuDiv .menuLists .listItem:hover .menuLink, .sideBar .settingsDiv .menuLists .listItem:hover .menuLink {
  color: var(--PrimaryColor);
  transition: 0;
}
.sideBar .menuDiv .menuLists .listItem:hover::before, .sideBar .settingsDiv .menuLists .listItem:hover::before {
  height: 100%;
  transition: 0.3s ease;
}
.sideBar .sideBarCard {
  width: 100%;
  padding: 1rem;
  text-align: center;
  position: relative;
}
.sideBar .sideBarCard .icon {
  position: absolute;
  background: var(--HoverColor);
  border: 10px solid var(--whiteColor);
  font-size: 3rem;
  border-radius: 50%;
  top: -8px;
  right: 50%;
  transform: translate(50%);
  z-index: 100;
}
.sideBar .sideBarCard .cardContent {
  position: relative;
  padding: 1rem;
  background: var(--HoverColor);
  border-radius: 10px;
  overflow: hidden;
}
.sideBar .sideBarCard .cardContent h3 {
  font-size: var(--h3FontSize);
  margin-top: 1rem;
  padding: 1rem 0;
  font-weight: 800;
  color: var(--blackColor);
}
.sideBar .sideBarCard .cardContent p {
  font-size: var(--normalFontSize);
  color: var(--textColor);
  padding-bottom: 1rem;
  font-weight: 500;
}
.sideBar .sideBarCard .cardContent .btn {
  position: relative;
  color: var(--textColor);
  z-index: 1000;
}
.sideBar .sideBarCard .cardContent .circle1, .sideBar .sideBarCard .cardContent .circle2 {
  position: absolute;
  background: var(--paleGreen);
  border-radius: 50%;
  opacity: 0.7;
}
.sideBar .sideBarCard .cardContent .circle1 {
  height: 100px;
  width: 100px;
  top: -50px;
  left: -50px;
}
.sideBar .sideBarCard .cardContent .circle2 {
  height: 150px;
  width: 150px;
  bottom: -80px;
  right: -70px;
  z-index: 1;
}

@media screen and (max-width: 820px) {
  .sideBar {
    padding-bottom: 2rem;
  }
  .sideBar .sideBarCard {
    display: none !important;
  }
}
@media screen and (max-width: 745px) {
  .sideBar .logoDiv {
    text-align: center;
    margin: auto;
    padding: 1.5rem 0.5rem 0;
  }
  .sideBar .logoDiv img {
    max-width: 50px;
    margin: 0;
    border-radius: 10px;
    padding: 0.5rem;
  }
  .sideBar .logoDiv h2 {
    display: none;
  }
  .sideBar .menuDiv .divTitle, .sideBar .menuDiv .smallText, .sideBar .settingsDiv .divTitle, .sideBar .settingsDiv .smallText {
    display: none;
  }
  .sideBar .menuDiv .menuLists .listItem .menuLink, .sideBar .settingsDiv .menuLists .listItem .menuLink {
    justify-content: center;
  }
  .sideBar .menuDiv .menuLists .listItem .menuLink .icon, .sideBar .settingsDiv .menuLists .listItem .menuLink .icon {
    font-size: var(--h1FontSize);
  }
}
@media screen and (max-width: 390px) {
  .sideBar .menuDiv, .sideBar .settingsDiv {
    text-align: center;
    margin: auto;
  }
  .sideBar .menuDiv .menuLists .listItem, .sideBar .settingsDiv .menuLists .listItem {
    padding: 0.2rem 0.5rem;
  }
  .sideBar .menuDiv .menuLists .listItem .menuLink, .sideBar .settingsDiv .menuLists .listItem .menuLink {
    justify-content: center;
  }
  .sideBar .menuDiv .menuLists .listItem .menuLink .icon, .sideBar .settingsDiv .menuLists .listItem .menuLink .icon {
    font-size: var(--h2FontSize);
  }
}/*# sourceMappingURL=sidebar.css.map */